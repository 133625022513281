<template>
  <b-card>
    <info />
  </b-card>
</template>

<script>

import {BCard} from 'bootstrap-vue'

import Info from './include/Info.vue'

export default {
  components: {
    BCard,
    Info,
  },
}
</script>
