<template lang="">
  <!-- <b-card > -->
  <b-overlay
    :show="loading"
    variant="light"
    :opacity="0.5"
    rounded="sm"
  >
    <br>
    <b-table
      striped
      responsive
      :items="items"
      :fields="fieldsTable"
    >
        
      <template #cell(_)="data">
        <b-button
          v-if="data.item.vehicle_id"
          size="sm"
          variant="primary"
          class="mr-1 btn-icon"
          @click="viewForm(data)"
        >
          <feather-icon icon="FolderIcon" />
        </b-button>
      </template>
    </b-table>
  </b-overlay>
  <!-- </b-card> -->
</template>
<script>
import {BTable, BButton, BOverlay} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BOverlay
  },
  props:{
    spb_id:{
      type:String,
    },
  },
  data() {
    return {
      dataurl:"/spb",
      baseroute:"billing-rent",
      fieldsTable: [
        { key: 'number_plate', label: 'Nopol'},
        { key: 'variant.model', label: 'Varian' },
        { key: 'year', label: 'Tahun'},
        { key: 'color', label: 'Warna'},
        '_'
      ],
      items:[],
      loading:false,
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData(){
      this.loading = true
      this.$http.get(this.dataurl+'/'+this.spb_id+'/vehicle').then(res=>{
        this.loading = false
        this.items = res.data.data
      })
      .finally(() => {this.loading = false})
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-maintenance',params : { vehicle_id: data.item.vehicle_id} })
    },
  }
}
</script>
<style lang="">

</style>
